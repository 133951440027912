$hr-color: #ddd;
$account-online-bg-color: #ada;
$account-offline-bg-color: #ccc;
$call-log-number-color: #ccc;
$call-incoming-accepted-color: #080;
$call-incoming-missed-color: #c00;
$call-outgoing-accepted-color: #48c;
$call-outgoing-missed-color: #a0a;
$settings-addendum-color: #ccc;


@mixin item-separator {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $hr-color;
  }
}


h1 { font-size: 1.75rem; }
h2 { font-size: 1.4rem; }
h3 { font-size: 1.2rem; }

hr {
  color: $hr-color;
}

.body {
  flex-grow: 1;
  overflow: auto;
  padding: 0.5rem 1rem;
}

.hidden {
  display: none;
}

.flexExpander {
  flex-grow: 1;
}


.actionIcons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.alert {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alert-heading {
  font-size: 110%;
}

.navbar {
  .navbar-nav {
    align-items: center;
  }
  .container {
    max-width: none !important;
  }
  .navbar-brand {
    cursor: pointer;
  }
}


.App {
  max-width: 640px;
  min-width: 320px;
  min-height: 240px;
  margin: 0 auto;
}


.Login {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: min(640px, 85vw);
  height: 100vh;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 110%;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  form > div {
    margin-bottom: 1rem;
  }
  form label {
    margin-bottom: 0;
  }
}


.NavBar {
  .AccountSelect {
    &.online {
      background-color: $account-online-bg-color;
    }
    &.offline {
      background-color: $account-offline-bg-color;
    }
  }
}

.SipClient {
  display: flex;
  height: calc(100vh);
  flex-direction: column;

  & > .tab-content {
    padding: 1rem;
    flex-grow: 1;
    overflow: auto;
  }

  &.CALL, &.SETTINGS {
    .nav-tabs, .tab-content {
      display: none;
    }
  }

}


.Dialer, .CallControls {
  .form-group {
    margin-bottom: 1rem;
  }
}


.CallControls {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 2rem;

  &__call {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  &__state {
    margin: 2rem;
    text-align: center;
  }

  &__name {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    font-weight: 500;
  }

  &__callActions {
    display: flex;
    justify-content: space-around;
  }

  &__numsOnHold {
    margin-top: 2rem;
  }
}


.Contacts {
  position: relative;

  &__item {
    @include item-separator;
    display: flex;
    padding: 0.5rem 0;
    border-radius: 0.25rem;
    justify-content: space-between;
    align-items: center;

    &__name {
      font-weight: 500;
    }
    &__number {
      font-size: 85%;
      opacity: 0.5;
    }
  }
}


.CallLog {
  &__item {
    @include item-separator;
    display: flex;
  }

  &__info {
    flex-grow: 1;
    &__name {
      display: flex;
      justify-content: space-between;

      .name {
        font-weight: 500;
      }
      .number {
        font-size: 85%;
        color: $call-log-number-color;
      }
    }

    &__detail {
      display: flex;
      font-size: 85%;

      >:not(:last-child) {
        margin-right: 0.5rem;
      }

      >:last-child {
        text-align: right;
        width: 3rem;
      }
    }

    &__icon {
      &.incoming.accepted {
        color: $call-incoming-accepted-color;
      }
      &.incoming.missed {
        color: $call-incoming-missed-color;
      }
      &.outgoing.accepted {
        color: $call-outgoing-accepted-color;
      }
      &.outgoing.missed {
        color: $call-outgoing-missed-color;
      }
    }
  }

  &__action {
    margin-left: 1rem;
  }
}


.CallIcon {
  &.dimmed {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}


.Settings {
  &__header {
    display: flex;
    border-bottom: 1px solid $hr-color;
    margin-bottom: 1rem;

    &__title {
      flex-grow: 1;
    }
  }

  .SettingsBody {
    h2 {
      margin-top: 1rem;
    }

    .addendum {
      color: $settings-addendum-color;
      font-size: 80%;
    }

  }
}
