.spinner {
  @keyframes rotateLoading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation-name: rotateLoading;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
