// progress
$progress-color: #ec7205;
$progress-color-done: #007ec3;

.icon {
  color: inherit;
  fill: currentColor;
  width: 1em;
}


.ActionModal {
  &__actions, &__customActions {
    margin: 0;
  }
  &__customActions {
    flex-grow: 1;
    // add margin-top for any second element excluding close button
    & > :not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}


.LoadingProgress {
  &__stripe {
    height: 3px;
    background-color: $progress-color;
  }
  &.done &__stripe {
    background-color: $progress-color-done;
  }
}


.spinner {
    @keyframes rotateLoading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation-name: rotateLoading;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: linear;
}
